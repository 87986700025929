/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// global.scss
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import '~swiper/scss/pagination';


//@font-face {
//  font-family: 'notoSans-black';
//  src: url("assets/fonts/NotoSansSC-Black.otf") format("opentype");
//  font-weight: bold;
//}
//
//@font-face {
//  font-family: 'notoSans-bold';
//  src: url("assets/fonts/NotoSansSC-Bold.otf") format("opentype");
//  font-weight: bold;
//}
//
//@font-face {
//  font-family: 'notoSans-light';
//  src: url("assets/fonts/NotoSansSC-Light.otf") format("opentype");
//  font-weight: normal;
//}
//
//@font-face {
//  font-family: 'notoSans-medium';
//  src: url("assets/fonts/NotoSansSC-Medium.otf") format("opentype");
//  font-weight: normal;
//}
//
//@font-face {
//  font-family: 'notoSans-regular';
//  src: url("assets/fonts/NotoSansSC-Regular.otf") format("opentype");
//  font-weight: normal;
//}
//
//@font-face {
//  font-family: 'notoSans-thin';
//  src: url("assets/fonts/NotoSansSC-Thin.otf") format("opentype");
//  font-weight: normal;
//}


@font-face {
  font-family: 'notoSans-black';
  src: url("assets/fonts/NotoSans-Black.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'notoSans-bold';
  src: url("assets/fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'notoSans-light';
  src: url("assets/fonts/NotoSans-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'notoSans-medium';
  src: url("assets/fonts/NotoSans-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'notoSans-regular';
  src: url("assets/fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'notoSans-thin';
  src: url("assets/fonts/NotoSans-Thin.ttf") format("truetype");
  font-weight: normal;
}



h1, h2, h3, h4, h5, h6 {
  font-family: 'notoSans-regular';
}

p {
  font-size: 13px;
  margin: 0;
}

//.text {
//  font-family: 'notoSans-regular';
//  color: #1c1d06;
//  font-size: 12px;
//}


//.font-black {
//  font-family: 'notoSans-black';
//  src: url("assets/fonts/NotoSansSC-Black.otf") format("opentype");
//  font-weight: bold;
//}
//
//.font-bold {
//  font-family: 'notoSans-bold';
//  src: url("assets/fonts/NotoSansSC-Bold.otf") format("opentype");
//  font-weight: bold;
//}
//
//.font-light {
//  font-family: 'notoSans-light';
//  src: url("assets/fonts/NotoSansSC-Light.otf") format("opentype");
//  font-weight: normal;
//}
//
//.font-medium {
//  font-family: 'notoSans-medium';
//  src: url("assets/fonts/NotoSansSC-Medium.otf") format("opentype");
//  font-weight: normal;
//}
//
//.font-regular {
//  font-family: 'notoSans-regular';
//  src: url("assets/fonts/NotoSansSC-Regular.otf") format("opentype");
//  font-weight: normal;
//}
//
//.font-thin {
//  font-family: 'notoSans-thin';
//  src: url("assets/fonts/NotoSansSC-Thin.otf") format("opentype");
//  font-weight: normal;
//}
.font-black {
  font-family: 'notoSans-black';
  src: url("assets/fonts/NotoSans-Black.ttf") format("truetype");
  font-weight: bold;
}

.font-bold {
  font-family: 'notoSans-bold';
  src: url("assets/fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

.font-light {
  font-family: 'notoSans-light';
  src: url("assets/fonts/NotoSans-Light.ttf") format("truetype");
  font-weight: normal;
}

.font-medium {
  font-family: 'notoSans-medium';
  src: url("assets/fonts/NotoSans-Medium.ttf") format("truetype");
  font-weight: normal;
}

.font-regular {
  font-family: 'notoSans-regular';
  src: url("assets/fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

.font-thin {
  font-family: 'notoSans-thin';
  src: url("assets/fonts/NotoSans-Thin.ttf") format("truetype");
  font-weight: normal;
}

ion-content {
  --padding-start: 10px;
  --padding-end: 10px;
  --ion-background-color: #F1F0F0;
  --padding-bottom: 30px;
  font-family: notoSans-regular !important;
  --ion-text-color: #201A1A;
}

ion-button {
  font-family: notoSans-regular !important;
}

.bg_preferiti {
  background-color: #231D2E;
}

.bg_esplora {
  background-color: #540014;
}

.bg_vini {
  background-color: #CE3154;
}

.bg_oli {
  background-color: #706033;
}

.bg_gusto {
  background-color: #231D2E;
}

.bg_curiosita {
  background-color: #202417;
}

.t_white {
  color: #fff;
}

.t_black {
  color: #000;
}

//pulsanti dei filtri
.bottone_filtro {
  border-radius: 5px;
  padding: 7px;
  width: 100%;
  margin-right: 10px;
  vertical-align: middle;
}

//.image_card {
//  width: 100%;
//  background-size: cover;
//  background-position: center;
//  background-repeat: no-repeat;
//  border-radius: 10px;
//}

.image_card_esperienza {
  width: 100%;
  height: 170px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.image_small_card {
  width: 100%;
  height: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.titolo {
  font-size: 22px;
}

.titolo_bold {
  font-size: 19px;
  font-family: 'notoSans-bold';
}

.sottotitolo {
  font-size: 15px;
}

.testo {
  font-size: 13px;
}

.filtro {

}

.menu {

}


.loadMore {
  display: flex;
  justify-content: center;

  ion-chip {
    color: #fff;
    border-radius: 7px;
    background-color: #6F5C2E
  }
}

.showAllButton {
  padding: 0;
  display: flex;
  justify-content: end;
  align-items: end;

  .showAllButton-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-right: 10px;
      font-size: 13px;
    }

    ion-icon {
      font-size: 10px
    }
  }
}

.pulsante {

}

.image_card_viaggi {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}


ion-header {
  --ion-background-color: #A1324B;

  ion-toolbar {
    --background: #A1324B;
  }
}

ion-tab-bar {
  --background: #A1324B;
}

ion-back-button {
  --color: white !important;
}

capacitor-google-map {
  display: inline-block;
  width: 100%;
  height: 100%;
}

select > option.hidden_option {
  display: none;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: transparent;
  border: 1px solid #CF2850;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.swiper .swiper-slide {
  height: auto;
  width: auto;
}

ion-select {

}

.bottone_filtro {
  background-color: #F4DDDC;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;


}

.social-link {
  color: black;
}
.dark .social-link {
  //color: white;
}


.dark a.social-link  {
  color: white;
}
.dark a.social-link ion-icon {
  color: black;
}

ion-icon.social {
  margin-right: 5px;
  font-size: 20px;
  background-color: white;
  border-radius: 1000px;
  padding: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.bottone_filtro_selected {
  background-color: #6F5C2E;
  color: white;
}

.filtro_produttore {
  background-color: white;
  border: 1px solid transparent;
  box-shadow: none;
  height: 100%;
  padding: 1px;
  display: flex;
  border-radius: 5px;

  img {
    width: 80px !important;
    object-fit: contain;
  }
}

.filtro_produttore_selected {
  border: 2px solid #6F5C2E;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.dark {
  .filtro_produttore_selected {
    border: 2px solid #CE3154;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
}

.d-none {
  display: none;
}

.img_slider {
  height: 67px !important;
  width: 100% !important;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px
}

.img_slider_product  {
  width: 100% !important;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px
}


.product-zoom-modal {
  //--height: auto;
  //height: 75%;
  //background-color: transparent;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px

}

.product-zoom-modal .modal-wrapper {
  position: absolute;
  //bottom: 0;
  min-height: initial;
  top: initial;
  height: 50%;
}

capacitor-google-maps {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.flag {
  width: 50px;
  opacity: 0.2;
}

.flag.selected {
  opacity: 1;
}


.search {
  border-radius: 7px;
  background-color: #ffffff;
}

.search-items {

}

app-map-picker {

  .map-picker-action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #F0F0F0;
    padding: 10px;

    .add-marker {
      display: flex;
      width: 180px;
      color: #12192b;
      font-size: 10px;

      border-radius: 7px;
      background-color: #F4DDDC
    }

    .address {
      border-radius: 7px;
      background-color: #ffffff;

    }

  }

  .map-picker-confirm {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: center;

    .confirm {
      color: #12192b;
      font-size: 10px;
      margin: 10px;
      border-radius: 7px;
      background-color: #F4DDDC
    }
  }
}

.logo_account {
  height: 36px;
  margin-right: 20px
}

app-tab1 ion-content ion-col,
app-tab2 ion-content ion-col,
app-tab3 ion-content ion-col,
app-tab4 ion-content ion-col,
app-tab5 ion-content ion-col,
{
  padding-left: 0;
  padding-right: 0;
}

//tab content margin
@media screen and (min-width: 576px) {
  .logo_account {
    height: 28px;
    //m
  }
  app-tab1 ion-content,
  app-tab2 ion-content,
  app-tab3 ion-content,
  app-tab4 ion-content,
  app-tab5 ion-content {
    --padding-start: 130px;
  }
}

@media screen and (min-width: 992px) {
  app-tab1 ion-content,
  app-tab2 ion-content,
  app-tab3 ion-content,
  app-tab4 ion-content,
  app-tab5 ion-content {
    --padding-start: 230px;
  }
}

.ion-tab-button:hover {
  color: black;
}

.tab-selected {
  color: black;
}


.material-symbols-outlined {
  color: white;
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.tab-selected .material-symbols-outlined {
  color: black;
  font-variation-settings: 'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.material-symbols-outlined_white {
  color: #CF2850;
  margin-left: 4px;
  font-variation-settings: 'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

ion-checkbox {

  --background-checked: #A1324B;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #A1324B;
}

body.dark {
  ion-content {
    --ion-background-color: #201A1A;
    --ion-text-color: #F1F0F0;
  }
}

.ios body.dark {
  ion-content {
    --ion-background-color: #201A1A;
    --ion-text-color: #F1F0F0;
  }
}

.md body.dark {
  ion-content {
    --ion-background-color: #201A1A;
    --ion-text-color: #F1F0F0;
  }
}

ion-input {
  --placeholder-color: red;

}

.sc-ion-input-md-h {
  --placeholder-opacity: .8 !important;
}

//@media (prefers-color-scheme: dark) {
//  :root {
//    /* Dark mode variables go here */
//    ion-content {
//      --ion-background-color: #201A1A;
//      --ion-text-color:#F1F0F0;
//    }
//
//  }
//}
.ion-toolbar-header {
  padding-right: 10px !important;
  padding-bottom: 8px !important;
  padding-left: 10px !important;
}

.ion-toolbar-header-home {
  padding-right: 10px !important;
  padding-bottom: 8px !important;
  padding-left: 10px !important;
}

ion-header .main_logo {
  margin: 0 10px;
}

.dark {
  ion-accordion-group {
    ion-accordion {
      .ion-accordion-toggle-icon {
        color: white;
      }
    }
  }
}

ion-item::part(native) {
  background-color: #A1324B !important;
  color: #fff !important;
  border-radius: 10px !important;
  margin-bottom: 5px;
}

.clipToSvgSocial{


}
.clipToSvgLogo1{
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.1));
}
.clipToSvgLogo{

  width: 80px;
  height: 80px;
  object-fit: fill;
  //clip-path: path("M132.733,220a41.57,41.57,0,0,1-29.587-12.254l-.083-.084a41.833,41.833,0,0,1-67.029-33.4c0-.034,0-.069,0-.1a41.842,41.842,0,0,1-23.778-71.024l.084-.083a41.827,41.827,0,0,1,33.52-67.023A41.823,41.823,0,0,1,87.287,0h0a41.57,41.57,0,0,1,29.587,12.254l.083.084a41.833,41.833,0,0,1,67.029,33.4c0,.034,0,.069,0,.1a41.847,41.847,0,0,1,23.778,71.024l-.084.083a41.827,41.827,0,0,1-33.52,67.023A41.823,41.823,0,0,1,132.733,220Z");
  clip-path: path("M 48.265625 80 C 44.230469 80.011719 40.355469 78.40625 37.507812 75.542969 L 37.476562 75.515625 C 32.871094 78.988281 26.695312 79.558594 21.53125 76.984375 C 16.367188 74.414062 13.101562 69.140625 13.101562 63.367188 C 13.101562 63.355469 13.101562 63.34375 13.101562 63.332031 C 7.386719 62.53125 2.617188 58.566406 0.785156 53.09375 C -1.046875 47.621094 0.375 41.585938 4.457031 37.503906 L 4.488281 37.476562 C 1.007812 32.863281 0.441406 26.675781 3.023438 21.507812 C 5.609375 16.34375 10.898438 13.085938 16.675781 13.101562 C 17.726562 5.589844 24.152344 0 31.742188 0 C 35.777344 -0.0117188 39.652344 1.59375 42.5 4.457031 L 42.53125 4.488281 C 47.136719 1.011719 53.3125 0.441406 58.476562 3.015625 C 63.640625 5.589844 66.90625 10.863281 66.902344 16.632812 C 66.902344 16.644531 66.902344 16.65625 66.902344 16.667969 C 72.617188 17.472656 77.386719 21.4375 79.21875 26.90625 C 81.050781 32.378906 79.628906 38.414062 75.550781 42.496094 L 75.519531 42.527344 C 79 47.136719 79.566406 53.324219 76.984375 58.492188 C 74.398438 63.660156 69.109375 66.917969 63.332031 66.898438 C 62.28125 74.410156 55.851562 80.003906 48.265625 80 Z M 48.265625 80");
  //clip-path: url('assets/mask.svg#Tracciato_8');
}
.custom-modal-radius {

  &::part(content) {
    border-radius: 20px 20px 0 0; // Here the border radius

  }
}
.object-fit-contain {
  object-fit: contain;
}

ion-popover {
  --width: 250px;
}

.spinner {
  color: #A1324B;
  margin-top: 20px;
  width: 100%;
}

.textCenter {
  text-align: center;
}
